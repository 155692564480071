var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"rgba(0,0,0,0.4)","overlay-opacity":"0.8","max-width":"520","persistent":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.editQuantity)}}},[_c('v-card',{ref:"form",attrs:{"flat":""}},[_c('v-card-title',[_c('span',{staticClass:"font-weight-bold blue-grey--text ml-1"},[_vm._v("Change Delivery Quantity")]),_c('v-spacer')],1),_c('v-card-text',[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[(_vm.details && _vm.details.branchId)?_c('ValidationProvider',[_c('v-text-field',{staticClass:"ft font-weight-bold font-size-md",attrs:{"label":"Branch Name","outlined":"","disabled":"","hint":"Branch Name"},model:{value:(_vm.details.branchId.name),callback:function ($$v) {_vm.$set(_vm.details.branchId, "name", $$v)},expression:"details.branchId.name"}})],1):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-md",attrs:{"label":"Delivery Quantity","outlined":"","hint":"Shared quantity delivered","error-messages":errors[0]},model:{value:(_vm.deliveries.changedQuantity),callback:function ($$v) {_vm.$set(_vm.deliveries, "changedQuantity", $$v)},expression:"deliveries.changedQuantity"}})]}}],null,true)})],1),(
              _vm.details && _vm.details.orderLineItemId.productRemainingDeliveries
            )?_c('div',{staticClass:"d-flex flex-column flex-grow-1 generate-deliveries"},[_c('span',{staticClass:" ft font-weight-bold font-size-md mb-1 primary--text"},[_vm._v("Remaining Quantity Left To Share")]),_c('span',{staticClass:"ft primary--text font-weight-bold font-size-md text-uppercase"},[_vm._v(" "+_vm._s(_vm.details.productId.name)+" - "+_vm._s(_vm.details.orderLineItemId.productRemainingDeliveries.quantity + parseInt(_vm.deliveries.originalQuantity) - parseInt( _vm.deliveries.changedQuantity ? _vm.deliveries.changedQuantity : 0 ))+" (Litres) ")])]):_vm._e()]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.actions}},[_c('span',{staticClass:" ft font-weight-medium "},[_vm._v("Close")])]),_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":_vm.loading ||
                (_vm.details &&
                  _vm.details.orderLineItemId.productRemainingDeliveries
                    .quantity <
                    Number(_vm.deliveries.changedQuantity) -
                      Number(_vm.deliveries.originalQuantity)) ||
                parseInt(
                  _vm.deliveries.changedQuantity ? _vm.deliveries.changedQuantity : 0
                ) === _vm.deliveries.originalQuantity}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Save")])])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }