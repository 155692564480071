<template>
  <v-dialog
    overlay-color="rgba(0,0,0,0.4)"
    overlay-opacity="0.8"
    v-model="state"
    max-width="520"
    persistent
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(editQuantity)" class="px-3">
        <v-card flat ref="form">
          <v-card-title>
            <span class="font-weight-bold blue-grey--text ml-1"
              >Change Delivery Quantity</span
            >
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <div class="d-flex flex-column flex-grow-1">
              <ValidationProvider v-if="details && details.branchId">
                <v-text-field
                  label="Branch Name"
                  outlined
                  disabled
                  v-model="details.branchId.name"
                  hint="Branch Name"
                  class="ft font-weight-bold font-size-md"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" rules="required|numeric">
                <v-text-field
                  label="Delivery Quantity"
                  outlined
                  v-model="deliveries.changedQuantity"
                  hint="Shared quantity delivered"
                  class="ft font-weight-medium font-size-md"
                  :error-messages="errors[0]"
                />
              </ValidationProvider>
            </div>
            <div
              v-if="
                details && details.orderLineItemId.productRemainingDeliveries
              "
              class="d-flex flex-column flex-grow-1 generate-deliveries"
            >
              <span class=" ft font-weight-bold font-size-md mb-1 primary--text"
                >Remaining Quantity Left To Share</span
              >

              <span
                class="ft primary--text font-weight-bold font-size-md text-uppercase"
              >
                {{ details.productId.name }} -
                {{
                  details.orderLineItemId.productRemainingDeliveries.quantity +
                    parseInt(deliveries.originalQuantity) -
                    parseInt(
                      deliveries.changedQuantity
                        ? deliveries.changedQuantity
                        : 0
                    )
                }}
                (Litres)
              </span>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="actions">
              <span class=" ft font-weight-medium ">Close</span>
            </v-btn>
            <v-btn
              type="submit"
              color="primary"
              :loading="loading"
              :disabled="
                loading ||
                  (details &&
                    details.orderLineItemId.productRemainingDeliveries
                      .quantity <
                      Number(deliveries.changedQuantity) -
                        Number(deliveries.originalQuantity)) ||
                  parseInt(
                    deliveries.changedQuantity ? deliveries.changedQuantity : 0
                  ) === deliveries.originalQuantity
              "
            >
              <span class="ft font-weight-medium">Save</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
export default {
  name: "EditDeliveryQuantity",
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    state: Boolean,
    loading: Boolean,
    details: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    deliveries: {
      changedQuantity: 0,
      originalQuantity: 0,
      productName: "",
      productId: null,
      orderLineItemId: null,
      branchId: null
    }
  }),
  watch: {
    details(payload) {
      const { quantity, id, branchId, productId, orderLineItemId } = payload;
      this.deliveries = {
        branchId: branchId?.id,
        orderLineItemId: orderLineItemId?.id,
        productId: productId?.id,
        productName: productId?.name,
        changedQuantity: quantity,
        originalQuantity: quantity,
        id
      };
    }
  },
  methods: {
    editQuantity() {
      this.$emit("editQuantity", this.deliveries);
    },
    actions() {
      this.$emit("actions", "edit");
    }
  }
};
</script>
